import React, { useEffect, useState } from 'react';
import './getStarted.css';

const GetStarted = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 100); // Adjust when scaling occurs
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="get-started-wrapper">
            {/* Image Container */}
            <div className="image-container">
                <div className={`background-image ${scrolled ? 'scrolled' : ''}`} />
                <div className="text-container1">
                    <div className="get-started-heading">Get Started</div>
                    <div className="get-started-subheading">Let’s give your business what it needs to grow.</div>
                </div>
            </div>

      {/* Information Container */}
      <div className="info-container">
    <div className="text-container">
        <p>
            Interested in learning more about how our services can benefit your business? 
            Contact us today to schedule a consultation. Our team of experts is here to
             answer any questions you may have and help you get started on the path to success.
        </p>
    </div>
    <div className="form-container">
        <form>
            <div className="form-row">
                <div className="form-group">
                    <label>
                        First Name:
                        <input type="text" name="firstName" required />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Last Name:
                        <input type="text" name="lastName" required />
                    </label>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>
                        Email:
                        <input type="email" name="email" required />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Company:
                        <input type="text" name="company" />
                    </label>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>
                        Phone Number:
                        <input type="tel" name="phone" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Job Title:
                        <input type="text" name="jobTitle" />
                    </label>
                </div>
            </div>
            <button type="submit" className="contact-us-btn">Contact Us</button>
        </form>
    </div>
</div>

        </div>
    );
};

export default GetStarted;
