import React from 'react';
import './Solutions.css'; // Make sure this matches your filename

function Solutions() {
  return (
    <div className="solutions-container"> {/* Updated class name */}
      <h1 className="welcome-title">Welcome to Davidson 
        Technology Solutions</h1>

     

      <div className="second-box">
        <div className="left-box">
          <h2 className="partner-title">Partner with Us for Success</h2>
        </div>
        <div className="right-box">
          <p className="description">
          At Davidson Technology Solutions, we understand that your business is unique. That's why we work closely with you to develop customized solutions that meet your specific needs.
           Below is a list of services that we can provide our top-notch expertise in:
          </p>
          <ul className="services-list">
            <li>Data Migration</li>
            <li>ETL Reporting</li>
            <li>Manual & Automated Testing</li>
            <li>BIG DATA</li>
            <li>Cloud (AWS Azure, Google)</li>
            <li>Data Quality</li>
            <li>Legacy applications maintenance, upgrades & conversion</li>
            <li>Data Analytics</li>
            <li>Website/App development</li>
            <li>Hadoop and Spark</li>
            <li>Machine Learning</li>
            <li>Deep Learning</li>
            <li>Artificial Intelligence</li>
            <li>Text Mining and NLP</li>
            <li>AVAYA</li>
            <li>Verint</li>
            <li>NICE</li>
            <li>IOT</li>
            <li>Design Thinking</li>
            <li>Software testing and Robotic Process Automation</li>
            <li>Project Management</li>
            <li>DevOps</li>
            <li>Process Audits (ISO)</li>
            <li>Full Digital Media Services</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
